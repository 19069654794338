<template>
    <v-container fluid class="mt-0 pa-0">
	<v-card class="page-menu mb-3" rounded="0"  flat>
		<v-sheet
			elevation="2"
			rounded="0"
			height="40"
			max-width="100%"
			class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-2"
		>
			<v-icon class="white--text mb-2">mdi-database</v-icon>
			マスタ管理・メンテナンス 
		</v-sheet>
		<v-card class="ma-5 rounded" >
			<v-system-bar color="gray" height="50">
				<v-card-subtitle class="text-h6 font-weight-bold">
					メールテンプレート更新	
				</v-card-subtitle>
				<v-spacer />
				                    <v-btn large class="ma-2 btn-edit" @click="clickSave">保存</v-btn>
                    <v-btn large class="ma-2 btn-close" @click="clickBack">戻る</v-btn>
			</v-system-bar>
		</v-card>
	</v-card >
	<v-card>
		<div style="width:800px" class="ma-5 pa-5">
		<v-simple-table class="plant-tbl">
			<colgroup>
			<col style="width:150px">
			</colgroup>
            <tr>
                <th>メール種類</th>
                <td>
                    {{mailInfo.mail_name}}
                </td>
            </tr>
            <tr>
                <th>
                    メール説明
                </th>
                <td style="background-color:#ddd">
                    <template v-if="userid==1">
                        <v-textarea
                        outlined dense
                        hide-details="auto"
                        v-model="mailInfo.overview"
                        >
                        </v-textarea>
                    </template>
                    <template v-else>
                        <div class="my-3 text-overview" v-html="mailInfo.overview"></div>
                    </template>
                </td>
            </tr>
            <tr>
                <th>
                    送信有無
                </th>
                <td>
                    <v-radio-group class="my-2" v-model="mailInfo.send_flg" hide-details="auto" row>
                        <v-radio
                        v-for="(item,key) in sendKbn"
                        :key="key"
                        :label="item.label"
                        :value="item.value"
                        >

                        </v-radio>
                    </v-radio-group>
                </td>
            </tr>
			<tr>
				<th>
                   メールタイトル 
				</th>
				<td>
					<v-text-field outlined dense 
                        style="width:500px"
						hide-details="auto"
						maxlength="80"
                        :error-messages="err.subject"
						v-model="mailInfo.subject">
					</v-text-field>
				</td>
			</tr>
			<tr>
				<th>
                    メール内容
				</th>
				<td>
					<v-textarea outlined dense 
						hide-details="auto"
                        height="400px"
                        :error-messages="err.content"
						v-model="mailInfo.content">
					</v-textarea>
				</td>

			</tr>
		</v-simple-table>
		</div>

	</v-card>
	</v-container>
</template>
<style scoped>
.text-overview{
    white-space: pre-wrap;
}
</style>
<script>
import { MESSAGE } from "../messages";
import {CONSTANT} from "../constant";
export default {
	data() {
		return {
            mode:'',
            userid:'',
            sendKbn:[
                {label:'送信する', value:1},
                {label:'送信しない', value:0},
            ],
            mailInfo:{
                mail_id:'',
                mail_name:'',
                subject:'',
                content:'',
                overview:'',
                send_flg:'1',
			},
			err:{
                mail_name:'',
                subject:'',
                content:'',
                overview:'',
                send_flg:'',
			}	
		}
	},
	mounted(){
        if(this.$store.state.mailId ===''){
            // メールの指定がない場合
            this.$router.push('/maillist');
            return ;
        }
        this.mailInfo.mail_id = this.$store.state.mailId;
        this.getMailtemplate();

	},
	methods: {
        /**
         * メールテンプレートの情報を取得
         */
        getMailtemplate(){
            this.axios.post('get-mail-template',{mail_id:this.mailInfo.mail_id})
            .then((res)=>{
                    if(res.data.resultCode !== CONSTANT.RETURN_NORMAL){
                        return ;
                    }
                    const data = res.data.resultData.mail_template;
                    this.mailInfo.mail_name = data.mail_name;
                    this.mailInfo.subject = data.subject;
                    this.mailInfo.content = data.content;
                    this.mailInfo.send_flg = data.send_flg; 
                    this.mailInfo.overview = data.overview;
                    this.userid = res.data.resultData.userid;
            });
        },
        inputCheck()
        {
            //error clear
            this.clearError();


            if(!this.mailInfo.subject){
                this.err.subject = [MESSAGE.formatByArr(MESSAGE.E001,'メールタイトル')];
            }
            if(!this.mailInfo.content){
                this.err.content = [MESSAGE.formatByArr(MESSAGE.E001,'メール本文')];
            }
            
            for (let key in this.err) {
                if (this.err[key].length > 0){
                    return true;
                }
            }
            return false;
        },
		/**
         * 更新ボタンクリック
		 * メールテンプレートの保存
		 */
		async clickSave(){
            
            // input check
            if(await this.inputCheck()){
                // in error
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }

            // 確認メッセージ
            const msgres = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                (this.mode=='i')?MESSAGE.R001:MESSAGE.R002);
            if(!msgres){
                return ;
            }

			const res = await this.axios.post('update-mail-template',this.mailInfo);
			if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,'メールテンプレート'));
                this.$store.commit('setMailId', '');
                this.$router.push("/maillist");
			}
		},
        /**
         * 戻るボタンクリック
         */
		clickBack(){
            this.$store.commit('setMailId', '');
            this.$router.push("/maillist");
		},
        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },

	},
}
</script>